<template>
  <div
    class="bg-white cursor-pointer hover:bg-WALIGHTBLUE-50 max-w-screen-2xl mb-1 mt-0 mx-auto p-4 rounded-lg shadow-2xl shadow-gray-100 lg:px-4"
  >
    <div class="flex items-center justify-between">
      <div class="flex flex-row">
        <div class="flex pr-1 w-44">
          <p class="text-WADARKBLUE-500 text-sm font-thin">{{ user?.name }}</p>
        </div>
        <div class="hidden px-1 w-56 sm:flex">
          <p class="text-WADARKBLUE-500 text-sm font-thin">
            {{ user.email }}
          </p>
        </div>
        <div class="hidden px-1 w-36 lg:flex">
          <p class="text-WADARKBLUE-500 text-sm font-thin">{{ user?.phone }}</p>
        </div>
        <div class="hidden px-1 w-36 lg:flex">
          <p class="text-WADARKBLUE-500 text-sm font-thin">
            {{ user?.role?.name || " " }}
          </p>
        </div>
        <div class="px-1 hidden md:flex w-36 md:w-96">
          <p class="text-WADARKBLUE-500 text-sm font-thin">
            {{ getGroupName(user?.groups) }}
          </p>
        </div>
      </div>
      <div class="flex">
        <div class="justify-end relative flex w-24">
          <button
            @click="openStatusModal(user)"
            class="flex font-thin text-sm items-center"
            :class="{
              'text-WAGREEN-500': user.status === 'Active',
              'text-WAORANGE-500': user.status === 'Disabled',
            }"
          >
            {{ user.status }}
            <img class="w-2 ml-1" src="/chevron-down-dark.svg" alt="chevron" />
          </button>
          <status-modal
            v-if="showStatus.status && showStatus.id === user._id"
            v-model="status"
            :data="statusData"
            @close="closeStatusModal"
          ></status-modal>
        </div>
        <div class="relative flex justify-end w-20">
          <!-- edit dropdown -->
          <option-dropdown-new
            @btnClick="openOptionDropDown(user)"
            @close="closeOptionDropdown"
            :show="
              showOptionDropdown.status && showOptionDropdown.id === user._id
            "
          >
            <button
              @click="$emit('edit', user)"
              class="px-3 py-2 flex justify-between items-center text-sm w-full text-gray-700 hover:bg-WALIGHTBLUE-50"
            >
              {{ $t("edit") }}
            </button>
            <button
              @click="openConfirmModal(user._id)"
              class="px-3 py-2 flex justify-between items-center w-full text-red-700 text-sm hover:bg-red-500 hover:text-white"
            >
              {{ $t("delete") }}
            </button>
          </option-dropdown-new>

          <div
            class="absolute -bottom-16 w-80"
            v-if="showConfirm.status && showConfirm.id === user._id"
          >
            <confirm-modal
              :show="showConfirm.status && showConfirm.id === user._id"
              @no="closeConfirmModal"
              @yes="confirmDelete(user._id)"
            ></confirm-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import StatusModal from "../StatusModal.vue";
import OptionDropdownNew from "../shared/option-dropdown/OptionDropdownNew.vue";
import ConfirmModal from "../ConfirmModal.vue";
import httpClient from "../../services/httpClient";

export default {
  components: { StatusModal, OptionDropdownNew, ConfirmModal },
  data() {
    return {
      statusData: [
        { id: 1, value: "Active" },
        { id: 2, value: "Disabled" },
      ],
      status: "",
      showStatus: { status: false, id: "" },
      showOptionDropdown: { status: false, id: "" },
      showConfirm: { status: false, id: "" },
    };
  },
  props: ["user"],

  methods: {
    ...mapActions("users", ["changeStatus"]),
    ...mapMutations("users", ["removeUser"]),

    getGroupName(groups) {
      const names = groups.map((group) => `${group.name}`).join(", ");
      return groups.length > 0 ? names : " ";
    },

    async deleteUser(id) {
      try {
        this.deleting = true;

        await httpClient.delete(`/admin/users/${this.user._id}`);

        this.deleting = false;
        this.removeUser(id);
      } catch (err) {
        this.deleting = false;
        console.error(err);
      }
    },

    openStatusModal(user) {
      this.showStatus.id = user._id;
      this.showStatus.status = true;
    },

    closeStatusModal(e) {
      if (!e) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      }
    },

    openOptionDropDown(user) {
      if (this.showStatus.status) {
        this.showStatus.status = false;
        this.showStatus.id = "";
      }

      this.showOptionDropdown.status = true;
      this.showOptionDropdown.id = user._id;
    },

    closeOptionDropdown(e) {
      if (!e) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showOptionDropdown.status = false;
        this.showOptionDropdown.id = "";
      }
    },

    confirmDelete(id) {
      this.closeConfirmModal();
      this.deleteUser(id);
    },

    openConfirmModal(id) {
      this.showConfirm.status = true;
      this.showConfirm.id = id;
    },

    closeConfirmModal(e) {
      if (!e) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      } else if (!this.$el.contains(e.target)) {
        this.showConfirm.status = false;
        this.showConfirm.id = "";
      }
    },
  },

  mounted() {
    document.addEventListener("click", (e) => {
      this.closeStatusModal(e);
      this.closeOptionDropdown(e);
      this.closeConfirmModal(e);
    });
  },

  beforeUnmount() {
    document.removeEventListener("click", (e) => {
      this.closeStatusModal(e);
      this.closeOptionDropdown(e);
      this.closeConfirmModal(e);
    });
  },

  watch: {
    async status(status) {
      await this.changeStatus({ status, id: this.user._id });
    },
  },
};
</script>
