<template>
  <Loader v-if="roleLoading" />
  <form v-else @submit.prevent="submit">
    <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
      <span>* {{ error }}</span>
    </div>
    <div class="flex flex-col sm:flex-row items-center gap-3 mb-4">
      <input
        class="rounded w-full"
        :placeholder="$t('first-name')"
        type="text"
        v-model="firstName"
      />
      <input
        class="rounded w-full"
        :placeholder="$t('last-name')"
        type="text"
        v-model="lastName"
      />
    </div>
    <div class="flex items-center flex-col sm:flex-row gap-3 mb-4">
      <input
        class="rounded w-full"
        :placeholder="$t('email')"
        type="email"
        v-model="email"
      />
      <input
        class="rounded w-full"
        :placeholder="$t('phone')"
        type="tel"
        v-model="phone"
      />
    </div>
    <div class="flex flex-col sm:flex-row items-center gap-3 mb-4">
      <select-input
        :placeholder="$t('role')"
        :data="getRoleInputData('name')"
        v-model="role"
        size="full"
        :returnId="true"
        :isObject="true"
      />

      <select-input
        :placeholder="$t('status')"
        :data="statusData"
        v-model="status"
        size="full"
      />
    </div>
    <div class="flex items-center flex-col sm:flex-row mb-4">
      <input
        class="rounded w-full sm:w-1/2"
        :placeholder="$t('password')"
        type="password"
        v-model="password"
      />
    </div>
    <div class="flex relative items-center justify-end gap-3">
      <button
        :disabled="loading"
        type="submit"
        class="rounded text-white py-1.5 px-4 bg-WAORANGE-500 text-sm"
      >
        {{
          user
            ? loading
              ? $t("editing")
              : $t("edit")
            : loading
            ? $t("adding")
            : $t("add-user")
        }}
      </button>
    </div>
  </form>
</template>

<script>
import SelectInput from "@/components/forms/SelectInput.vue";
import Loader from "@/components/Loader.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";
import httpClient from "../../services/httpClient";

export default {
  components: { SelectInput, Loader },

  async created() {
    await this.getRoles();
  },

  props: ["user"],
  data() {
    return {
      firstName: this.user ? this.user?.firstName : "",
      lastName: this.user ? this.user?.lastName : "",
      email: this.user ? this.user.email : "",
      phone: this.user ? this.user.phone : "",
      status: this.user ? this.user.status : "",
      role: {
        id: this.user?.role?.id || "",
        name: this.user?.role?.name || "",
      },
      password: "",
      loading: false,
      error: null,
      statusData: [
        { id: 1, value: "Active" },
        { id: 2, value: "Disabled" },
      ],
    };
  },
  methods: {
    ...mapMutations("users", ["addUser", "updateUser", "removeUser"]),
    ...mapActions("roles", ["getRoles"]),
    async submit() {
      if (this.error) this.error = null;

      if (
        !this.firstName ||
        !this.lastName ||
        !this.email ||
        !this.status ||
        (!this.user && !this.password) ||
        !this.role?.id
      )
        return (this.error = "Please provide in all fields");

      try {
        this.loading = true;

        // For updating user
        if (this.user) {
          const { data } = await httpClient.put(
            `/admin/users/${this.user.id}`,
            {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              ...(this.phone && { phone: this.phone }),
              role: this.role.id,
              status: this.status,
              password: this.password,
            }
          );
          this.loading = false;
          this.updateUser(data);
          this.$emit("onClose");
          return;
        }

        const { data } = await httpClient.post(`/admin/users`, {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          ...(this.phone && { phone: this.phone }),
          role: this.role.id,
          status: this.status,
          password: this.password,
        });

        this.loading = false;
        this.addUser(data);
        this.$emit("onClose");
      } catch (err) {
        this.loading = false;
        if (err) {
          this.error =
            err.response && err.response?.data.error
              ? err.response?.data?.error
              : err.error || err.message;
        }
      }
    },

    getUsersInputData(name) {
      return this.userList.map((user) => ({ id: user.id, value: user[name] }));
    },
    getRoleInputData(name) {
      return this.roleList.map((role) => ({ id: role._id, value: role[name] }));
    },
  },
  computed: {
    ...mapGetters("roles", { roleLoading: "loading" }),
    ...mapGetters("roles", { roleList: "list" }),
  },
};
</script>

<style scoped>
input {
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

form {
  width: 80vw;
}

@media screen and (min-width: 640px) {
  form {
    width: 400px;
  }
}
</style>
