<template>
  <Modal :show="showModal" @onClose="closeModal">
    <template #header>{{
      selectedUser ? `${$t("edit")} ${selectedUser?.name}` : $t("create-user")
    }}</template>
    <template #body
      ><user-form @onClose="closeModal" :user="selectedUser"
    /></template>
  </Modal>

  <Loader v-if="loading && list.length === 0" />
  <div v-else>
    <section class="max-w-screen-2xl mx-auto pt-6 px-2 sm:px-6 lg:px-8">
      <div class="flex flex-row items-center justify-start">
        <div class="flex items-center">
          <div class="mr-2">
            <img src="/icon-title-users.svg" class="h-5 w-5" />
          </div>
          <div>
            <h1 class="font-bold text-WADARKBLUE-500 text-base uppercase">
              {{ $t("users") }}
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section class="max-w-screen-2xl mx-auto px-2 py-6 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <button
            @click="openModal"
            class="
              bg-WAORANGE-500
              hover:bg-WAORANGE-400
              mr-2
              px-4
              py-2
              rounded-lg
              text-white text-xs
              uppercase
            "
          >
            + {{ $t("add-user") }}
          </button>
        </div>
        <user-filter></user-filter>
      </div>
    </section>
    <section class="lg:px-8 max-w-screen-2xl mx-auto px-2 sm:px-6">
      <user-table-row />
      <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
        <span>* {{ error }}</span>
      </div>
      <div v-if="filteredUsers.length > 0">
        <User
          v-for="user in filteredUsers"
          :key="user.id"
          :user="user"
          @edit="selectUser"
        />
      </div>
      <div v-else>
        <h1 class="text-sm text-center text-WADARKBLUE-500 my-10">
          {{ $t("no-data") }}
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
import User from "@/components/users/User.vue";
import UserTableRow from "@/components/users/UserTableRow.vue";
import UserFilter from "@/components/users/UserFilter.vue";
import UserForm from "@/components/users/UserForm.vue";
import Loader from "@/components/Loader.vue";
import Modal from "@/components/Modal";

import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return { showModal: false, selectedUser: null };
  },
  computed: {
    ...mapGetters("users", ["list", "loading", "error", "filteredUsers"]),
    ...mapGetters(["currentUser"]),
    isAdmin() {
      return this.currentUser && this.currentUser?.role?.user?.admin;
    },
    dataLoaded: () => !this.loading && this.list.length > 0,
  },

  components: {
    User,
    Modal,
    UserForm,
    UserTableRow,
    UserFilter,
    Loader,
  },

  methods: {
    ...mapActions("users", ["getUsers", "error"]),
    openModal() {
      this.selectedUser = null;
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
      if (this.selectedUser) this.selectedUser = null;
    },

    selectUser(user) {
      this.showModal = true;
      this.selectedUser = user;
    },
  },
  async created() {
    await this.getUsers();
  },
};
</script>
