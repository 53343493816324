<template>
  <div class="relative">
    <button @click.prevent="toggleFilter" class="flex items-center">
      <img src="/icon-filters.svg" alt="filter" />
    </button>
    <Transition name="slide-down-fade">
      <div class="absolute z-50 right-0 top-10 filter_class" v-if="showFilter">
        <div
          class="
            flex flex-col
            items-center
            justify-end
            gap-2
            bg-white
            shadow-md
            p-3
            rounded
          "
        >
          <select-input
            :data="getInputData('name')"
            :placeholder="$t('name')"
            :filterMode="true"
            v-model="name"
            size="small"
            width="full"
          />
          <select-input
            :data="getInputData('email')"
            :placeholder="$t('email')"
            :filterMode="true"
            v-model="email"
            size="small"
            width="full"
          />
          <select-input
            :data="getInputData('phone')"
            :placeholder="$t('phone')"
            :filterMode="true"
            v-model="phone"
            size="small"
            width="full"
          />
          <select-input
            :data="getRoleData()"
            :placeholder="$t('role')"
            :filterMode="true"
            v-model="role"
            size="small"
            width="full"
          />
          <select-input
            :data="statusData"
            :placeholder="$t('status')"
            :filterMode="true"
            v-model="status"
            size="small"
            width="full"
          />
        </div></div
    ></Transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import SelectInput from "@/components/forms/SelectInput.vue";

export default {
  computed: {
    ...mapGetters("users", ["list"]),
  },
  components: { SelectInput },

  data() {
    return {
      name: "",
      email: "",
      role: "",
      status: "",
      phone: "",
      filterData: { name: "", email: "", phone: "", role: "", status: "" },
      statusData: [
        { id: 1, value: "Active" },
        { id: 2, value: "Disabled" },
      ],
      showFilter: false,
    };
  },
  methods: {
    ...mapActions("users", ["filterUser"]),

    getInputData(name) {
      return this.list
        .map((li, idx) => ({ id: idx, value: li[name] }))
        .filter((li) => !!li.value);
    },

    getRoleData() {
      let uniqueroles = [];
      const roles = this.list
        .map((li) => ({
          id: li?.role?.id,
          value: li?.role?.name,
        }))
        .filter((li) => !!li.id && !!li.value);

      roles.forEach((role) => {
        if (
          !uniqueroles.find(
            (r) => r?.value.toLowerCase() === role?.value.toLowerCase()
          )
        ) {
          uniqueroles.push(role);
        }
      });

      return uniqueroles;
    },

    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.showFilter = false;
      }
    },
  },
  watch: {
    name(newVal) {
      this.filterData = { ...this.filterData, name: newVal };
      this.filterUser(this.filterData);
    },
    email(newVal) {
      this.filterData = { ...this.filterData, email: newVal };
      this.filterUser(this.filterData);
    },
    phone(newVal) {
      this.filterData = { ...this.filterData, phone: newVal };
      this.filterUser(this.filterData);
    },
    role(newVal) {
      this.filterData = { ...this.filterData, role: newVal };
      this.filterUser(this.filterData);
    },
    status(newVal) {
      this.filterData = { ...this.filterData, status: newVal };
      this.filterUser(this.filterData);
    },
  },
  mounted() {
    this.getRoleData();
    document.addEventListener("click", this.close);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.close);
  },
};
</script>
